import React, { useState } from "react";
import axios from "axios";

interface ApiResponse {
  assistantMessage: string;
  tokenCount: number;
}

const AttachmentAI = () => {
  const [assistantMessage, setAssistantMessage] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the selected file
    if (!file) return;

    const formData:any = new FormData();
    formData.append("file", file);
    formData.append("submit_analyze", "true");

    try {
      // Send the file to the API
      const apiResponse = await axios.post<ApiResponse>(
        "https://oparantap.in/vider/api.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Extract and set the assistantMessage
      setAssistantMessage(apiResponse.data.assistantMessage || "No message received");
      setError(""); // Clear any previous error
    } catch (error) {
      setError("Error uploading PDF");
      setAssistantMessage(""); // Clear previous message
      console.error(error);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Upload PDF</h1>
      <input type="file" accept="application/pdf" onChange={handleFileUpload} />
      {assistantMessage && (
        <div style={{ marginTop: "20px", color: "green" }}>
          <h3>Assistant Message:</h3>
          <p>{assistantMessage}</p>
        </div>
      )}
      {error && (
        <div style={{ marginTop: "20px", color: "red" }}>
          <h3>{error}</h3>
        </div>
      )}
    </div>
  );
};

export default AttachmentAI;
